import React, { useEffect, useState } from "react";
import { fetchAdminStats } from "./api";
import { Routes, Route } from "react-router-dom";
import Header from "./Template/Header";
import Orders from "./Orders";
import Users from "./Users";
import TransactionsPage from "./TransactionsPage";
import AdminFooter from "./Template/Footer";


function AdminArea() {
  const [stats, setStats] = useState({ totalUsers: 0, totalOrders: 0, totalSales: 0, pendingOrders: 0 });

  useEffect(() => {
    fetchAdminStats().then((data) => setStats(data));
  }, []);

  return (
    <>
    <Header />
    <div className="admin-container">

          <div className="admin-content">
              <div className="dashboard">
                  <h1>Admin Dashboard</h1>
                  <div className="stats-container">
                      <div className="stat-box">
                          <h3>Total Users</h3>
                          <p>{stats.totalUsers}</p>
                      </div>
                      <div className="stat-box">
                          <h3>Total Orders</h3>
                          <p>{stats.totalOrders}</p>
                      </div>

                      <div className="stat-box">
                        <h3>Total Sales</h3>
                        <p>
                            {typeof stats.totalSales === 'number' 
                            ? `$${stats.totalSales.toFixed(2)}` 
                            : stats.totalSales !== null 
                                ? `$${parseFloat(stats.totalSales).toFixed(2)}` 
                                : 'N/A'}
                        </p>
                        </div>

                      <div className="stat-box">
                          <h3>Pending Orders</h3>
                          <p>{stats.pendingOrders}</p>
                      </div>
                  </div>
              </div>

              <Routes>
                  <Route path="orders" element={<Orders />} />
                  <Route path="users" element={<Users />} />
                  <Route path="transactions" element={<TransactionsPage />} />
              </Routes>
          </div>
          <AdminFooter />
      </div></>
  );
}

export default AdminArea;
