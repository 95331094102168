import React from "react";
import P2 from "../../assest/2baaa140a5c7324ffa542714115c556c.png";
import P3 from "../../assest/b26639388d65603c6683cab8da81bdca.png";
import P5 from "../../assest/338b7d461f9798f818110c5c91e0b904.webp";
import P6 from "../../assest/f33b5b00aaaaa099ad37a11a676079a7.webp";
import P7 from "../../assest/030e5ea8d38e310832c4b4362abe5212_1.jpg";




const clientTestimonials = [
  {
    name: "Jason Jouan",
    title: "Serial Entrepreneur & Property Investor",
    image: P6, // Assuming P6 is the image source for Jason
    quote: `"Prime Authority PR revolutionized my digital presence, propelling my business to new heights. With top-ranking Google search results, monthly press features, and a coveted Google Knowledge Panel, they've established me as a trusted authority in real estate. Their expertise is unmatched, and their impact is undeniable."`,
    featuredOn: [P2, P3, P5], // Array of logos (Yahoo News, Forbes, Clout)
    caseStudyLink: "/case-study/jason", // Link to the case study
  },
  // {
  //   name: "Rishi Jain",
  //   title: "Serial Entrepreneur",
  //   image: 'http://localhost:3000/static/media/dd5a967dec10da728e0780f2025166c0.88302c576bd991d1c976.jpeg', // Assuming P7 is the image source for Sean
  //   quote: `"Prime Authority PR revolutionized my digital presence, propelling my business to new heights. With top-ranking Google search results, monthly press features, and a coveted Google Knowledge Panel, they've established me as a trusted authority in real estate. Their expertise is unmatched, and their impact is undeniable."`,
  //   featuredOn: [P3, P2, P5], // Array of logos (Yahoo News, Forbes, Clout)
  //   caseStudyLink: "/1", // Link to the case study
  // },
  // {
  //   name: "Sean Borg",
  //   title: "Serial Entrepreneur",
  //   image: P7, // Assuming P7 is the image source for Sean
  //   quote: `"Prime Authority PR revolutionized my digital presence, propelling my business to new heights. With top-ranking Google search results, monthly press features, and a coveted Google Knowledge Panel, they've established me as a trusted authority in real estate. Their expertise is unmatched, and their impact is undeniable."`,
  //   featuredOn: [P3, P2, P5], // Array of logos (Yahoo News, Forbes, Clout)
  //   caseStudyLink: "#", // Link to the case study
  // },
  // {
  //   name: "Sean Borg",
  //   title: "Serial Entrepreneur",
  //   image: P7, // Assuming P7 is the image source for Sean
  //   quote: `"Prime Authority PR revolutionized my digital presence, propelling my business to new heights. With top-ranking Google search results, monthly press features, and a coveted Google Knowledge Panel, they've established me as a trusted authority in real estate. Their expertise is unmatched, and their impact is undeniable."`,
  //   featuredOn: [P3, P2, P5], // Array of logos (Yahoo News, Forbes, Clout)
  //   caseStudyLink: "#", // Link to the case study
  // },
  // Add more clients as needed
];


function Client() {
  return (
    <div
      data-page-element="SectionContainer/V1"
      className="container fullContainer id-6Z-6K9P7D-7864"
      data-paint-colors="lightest"
    >
      <div className="containerInnerV2">


        <div
          data-page-element="RowContainer/V1"
          className="row id-6Z-6K9P7D-7865"
          data-slot-name="default"
        >
          <div
            data-page-element="ColContainer/V1"
            className="col-md-12 innerContent col_left id-6Z-6K9P7D-7866"
          >
            <div className="col-inner">
              <div
                data-page-element="SubHeadline/V1"
                className="elSubheadlineWrapper id-6Z-6K9P7D-7867"
              >
                <h2 className="elSubheadline" data-style-guide-subheadline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-7868"
                      data-align-selector=".elSubheadline"
                      data-slot-name="default"
                    >
                      OUR CLIENTS GET RESULTS
                    </span>
                  </span>
                </h2>
              </div>
              <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-6K9P7D-7870"
              >
                <h1 className="elHeadline" data-style-guide-headline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-7871"
                      data-align-selector=".elHeadline"
                      data-slot-name="default"
                    >
                      Here’s What Clients Have To Say About​ Us
                    </span>
                  </span>
                </h1>
              </div>
              {/* <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-6K9P7D-7896"
              >
                <h1 className="elHeadline" data-style-guide-headline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-7897"
                      data-align-selector=".elHeadline"
                      data-slot-name="default"
                    >
                      Prime Authority PR
                    </span>
                  </span>
                </h1>
              </div> */}
            </div>
          </div>
        </div>

        <div>
    {clientTestimonials.map((client, index) => (
      <React.Fragment key={index}>
        <div
          data-page-element="RowContainer/V1"
          className={`row id-6Z-9JGGmA-88 ${index % 2 !== 0 ? "rowreverse" : ""}`}
          data-slot-name="default"
        >
          {/* Text content */}
          <div
            data-page-element="ColContainer/V1"
            className={`col-md-6 innerContent ${index % 2 === 0 ? "col_left" : "col_right"}`}
          >
            <div className="col-inner">
              <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-9JGGmA-92"
              >
                <h1 className="elHeadline mt-3" data-style-guide-headline="m">
                  <span>{client.name}</span>
                </h1>
              </div>
              <div
                data-page-element="SubHeadline/V1"
                className="elSubheadlineWrapper id-6Z-9JGGmA-95"
              >
                <h2 className="elSubheadline minus_1" data-style-guide-subheadline="m">
                  <span>{client.title}</span>
                </h2>
              </div>
              <div
                data-page-element="Paragraph/V1"
                className="elParagraphWrapper id-6Z-9JGGmA-98"
              >
                <p className="elParagraph" data-style-guide-content="m">
                  <span>{client.quote}</span>
                </p>
              </div>
              <div
                data-page-element="SubHeadline/V1"
                className="elSubheadlineWrapper id-6Z-9JGGmA-101"
              >
                <h2 className="elSubheadline" data-style-guide-subheadline="m">
                  <span>Featured on:</span>
                </h2>
              </div>
              <div
                data-page-element="SectionContainer/V1"
                className="container fullContainer id-6Z-9JGGmA-104"
                data-paint-colors="lightest"
              >
                <div className="containerInnerV2">
                  <div
                    data-page-element="RowContainer/V1"
                    className="row id-6Z-9JGGmA-105"
                    data-slot-name="default"
                  >
                    {client.featuredOn.map((logo, logoIndex) => (
                      <div
                        key={logoIndex}
                        data-page-element="ColContainer/V1"
                        className="col-md-4 innerContent col_left id-6Z-9JGGmA-106"
                      >
                        <div className="col-inner">
                          <div
                            data-page-element="Image/V2"
                            className="elImageWrapper de-image-block id-6Z-9JGGmA-107"
                            data-liquid-replace="item"
                          >
                            <img
                              className="elImage"
                              src={logo}
                              width={222}
                              height={60}
                              data-max-width="1000px"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>


              <div
                data-page-element="Button/V1"
                className="elBTN id-6Z-b4YjXG-8"
                data-liquid-replace="item"
              >
                <a
                  target="_blank"
                  href={client.caseStudyLink}
                  className="elButton mob-show"
                  aria-label="Open Case Study"
                  data-disabled="false"
                  data-show-button-ids
                  data-hide-button-ids
                  data-on-submit-go-to
                  data-param-submittingtext="Submitting..."
                  data-style-guide-button="style1"
                  rel="noopener"
                >
                  <span className="elButtonMain">
                    <span className="elButtonMainText elButtonText">
                      Open Case Study
                    </span>
                  </span>
                  <span className="elButtonSub" />
                </a>
              </div>
            </div>
          </div>

          {/* Image content */}
          <div
            data-page-element="ColContainer/V1"
            className={`col-md-6 innerContent ${index % 2 === 0 ? "col_right" : "col_left"}`}
          >
            <div className="col-inner">
              <div
                data-page-element="Image/V2"
                className="elImageWrapper de-image-block id-6Z-9JGGmA-90 mob-hidden"
                data-liquid-replace="item"
              >
                <img
                  className="elImage"
                  src={client.image}
                  width={1024}
                  height={644}
                  data-image-quality={100}
                  data-final-width={100}
                  data-final-height={327}
                  data-max-width="1000px"
                />
              </div>

              {/* <div
                data-page-element="Button/V1"
                className="elBTN id-6Z-b4YjXG-17"
                data-liquid-replace="item"
              >
                <a
                  // target="_blank"
                  href={client.caseStudyLink}
                  className="elButton mob-hidden"
                  aria-label
                  data-disabled="false"
                  data-show-button-ids
                  data-hide-button-ids
                  data-on-submit-go-to
                  data-param-submittingtext="Submitting..."
                  data-style-guide-button="style1"
                  rel="noopener"
                >
                  <span className="elButtonMain">
                    <i className="fas fa-spinner fa-spin elButtonSpinner elButtonText" />
                    <span className="elButtonMainText elButtonText">
                      Open Case Study
                    </span>
                  </span>
                  <span className="elButtonSub" />
                </a>
              </div> */}


            </div>
          </div>
        </div>

        {/* Divider */}
        {index < clientTestimonials.length - 1 && (
          <hr className="testimonial-divider" style={{ border: "1px solid #ccc", margin: "40px 0" }} />
        )}
      </React.Fragment>
    ))}
  </div>

      </div>
    </div>
  );
}

export default Client;
