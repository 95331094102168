import ReactGA from 'react-ga4';

// Initialize Google Analytics
export const initGA = () => {
    ReactGA.initialize('G-7MKXSDQ96V'); // Replace with your Measurement ID
};

// Track Page Views
export const trackPageView = (path) => {
    ReactGA.send({ hitType: 'pageview', page: path });
};
