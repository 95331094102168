import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { initGA, trackPageView } from "./analytics";

const GoogleAnalyticsTracker = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        // Initialize Google Analytics on the first load
        initGA();
    }, []);

    useEffect(() => {
        // Track page views on route change
        trackPageView(location.pathname + location.search);
    }, [location]);

    return children;
};

export default GoogleAnalyticsTracker;
